import DateTime from "@/lib/DateTime";

class ProjectItem {
    projectId;
    name;
    description;
    created;
    lastOpened;
    
    constructor(o) {
        this.projectId = ''
        this.name = ''
        this.description = '',
        this.created = Date.now()
        this.lastOpened = Date.now()
        
        if (o != null)
            this.assign(o)
    }
    
    assign(o) {
        this.projectId = o.projectId;
        this.name = o.name;
        this.description = o.description;
        this.created = DateTime.parseDate(o.created)
        this.lastOpened = DateTime.parseDateTime(o.lastOpened)
    }
}

export default ProjectItem