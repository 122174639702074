<template>
  <div v-if="!userStore.isLoggedIn">
    Please login
  </div>
  <div v-else class="projects-page panel-background">
    <div class="client general-panel">
      <h1>Projects</h1>

      <div class="project-actions">
        <button type="button" class="btn btn-secondary" @click="showCreateProject = true">Create project</button>
        <button type="button" class="btn btn-secondary" @click="refreshProject_Click">Refresh projects</button>
      </div>

      <div class="project-list-container">
        <div class="project-list">
          <div class="project-list-box base-list-box">
            <div class="list-box-scroller">
              <template v-if="projectStore.all.length === 0">
                <p class="text-center p-3">No projects have been created. Click "Create Project" to get started.</p>
              </template>
              <template v-else>
                <div class="item project-list-item" v-for="project in projectStore.sorted" :key="project.projectId" :class="{ selected: projectStore.current === project }" @click="selectProject(project)" @dblclick="selectProjectAndConfirm(project)">
                  <h5>{{ project.name }}</h5>
                  {{ getShortProjectDescription(project) }}
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="sel-project">
          <template v-if="projectStore.current == null">
            <p>{{ Strings.PROJECT_SELECT_A_PROJECT_TO_VIEW_OPTIONS }}</p>
          </template>
          <template v-else>
            <h3>{{ projectStore.current.name }}</h3>
            <p v-if="projectStore.current.description === ''">{{ Strings.PROJECT_NO_PROJECT_DESCRIPTION_TEXT }}</p>
            <p v-else>{{ projectStore.current.description }}</p>

            <p>
              <router-link :to="{ name: 'data' }" class="btn btn-secondary text-white" href="#">View and Create Data Sets</router-link>
            </p>

            <div class="sel-project-actions">
<!--              <button class="btn btn-secondary" @click="editProject_Click">{{ Strings.PROJECT_EDIT_PROJECT_BUTTON }}</button>-->
              <button class="btn btn-danger" @click="deleteProject_Click">{{ Strings.PROJECT_DELETE_PROJECT_BUTTON }}</button>
            </div>

          </template>
        </div>
      </div>
    </div>
  </div>

  <create-project-dialog v-if="showCreateProject" @cancel="showCreateProject = false" @project-created="projectCreated" />
</template>

<script setup>
import Strings from "@/lib/lang/Strings";
import { useProjectStore } from "@/stores/projectStore";
import { getProjectsAsync, deleteProjectAsync } from "@/api/projectsApi";
import {useUserStore} from "@/stores/userStore";
import { onMounted, ref } from "vue";
import CreateProjectDialog from "@/components/ProjectsPage/CreateProjectDialog";

const userStore = useUserStore()
const projectStore = useProjectStore()

const showCreateProject = ref(false)

async function refreshProjects() {
  projectStore.$reset()
  const projects = await getProjectsAsync(userStore.token)
  projectStore.updateProjects(projects)
}

async function refreshProject_Click() {
  await refreshProjects()
}
function openProject_Click() {

}
function editProject_Click() {

}
async function deleteProject_Click() {
  const project = projectStore.current

  if (project == null)
    return

  if (!confirm(`Delete project: ${project.name}?`))
    return

  await deleteProjectAsync(userStore.token, project.projectId)

  projectStore.current = null

  await refreshProjects()
}

function projectCreated() {
  showCreateProject.value = false
  refreshProjects()
}

function getShortProjectDescription(p) {

}

function selectProject(project) {
  projectStore.current = project
}

function selectProjectAndConfirm(project) {

}

onMounted(async () => {
  await refreshProjects()
})

</script>

<style scoped>
.projects-page {
  display: flex;
  flex: 1;
  justify-content: center;
}

.projects-page .client {
  margin: 1rem;
  width: 800px;
  max-width: 90%;
  display: flex;
  flex-direction: column;
}

.projects-page .client .project-actions {
  position: relative;
}

.projects-page .client .project-list-container {
  flex: 1;
  margin: 1rem 0;
  display: flex;
  flex-direction: row;
}

.projects-page .client .project-list-container .project-list {
  width: 50%;
  max-width: 400px;
  display: flex;
}

.projects-page .client .project-list-container .project-list .project-list-box {
  flex: 1;
}

.projects-page .client .project-list-container .project-list .project-list-box .project-list-item {
  padding: 0.5rem;
}

.projects-page .client .project-list-container .sel-project {
  flex: 1;
  border: 1px solid #eee;
  padding: 1rem;
}

.projects-page .client .project-list-container .sel-project .sel-project-actions button {
  margin-right: 0.5rem;
}

.projects-page .client .project-actions .btn {
  margin-right: 0.5rem;
}

</style>