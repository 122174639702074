import config from "@/sys/config";
import {getJsonAsync, postJsonAsync, deleteAsync} from "@/api/apiUtils";
import ProjectItem from "@/lib/entities/ProjectItem";

async function getProjectsAsync(token) {
    if (token == null) throw new Error('token is null')

    const url = config.SERVICE_URL + 'projects?token=' + token
    const resp = await getJsonAsync(url, token)
    if (resp.status === 200) {
        const arr = await resp.json()
        return arr.map(p => new ProjectItem(p))
    }
    throw new Error('Projects error')
}

async function createProjectAsync(token, data) {
    if (token == null) throw new Error('token is null')
    if (data === null) throw new Error('layerGroup is null')

    const url = config.SERVICE_URL + `projects`
    const resp = await postJsonAsync(url, data, token)

    if (resp.status !== 200) {
        throw new Error('Error creating project')
    }
}

async function deleteProjectAsync(token, projectId) {
    if (token == null) throw new Error('token is null')
    if (projectId == null) throw new Error('projectId is null')

    const url = config.SERVICE_URL + `projects/${projectId}`

    const resp = await deleteAsync(url, null, token)

    if (resp.status !== 200) {
        throw new Error('Error deleting project')
    }
}

export {
    getProjectsAsync,
    createProjectAsync,
    deleteProjectAsync
}