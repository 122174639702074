<template>
  <div class="dialog">
    <div class="dialog-background"/>
    <div class="dialog-content general-panel">
      <div class="window-controls"><a href="#" @click.prevent="emit('cancel')">&times;</a></div>
      <h3>Sign-up</h3>

      <p>Fill out the form below to request a Climate Insights account.</p>

      <div class="sign-up-form scrollable-host">
        <div class="scrollable">
          <h4>Login details</h4>

          <div class="form-group">
            <label for="signup_emailAddress">Email address <span class="required">*</span></label>
            <input type="text" class="form-control" id="signup_emailAddress" v-model="emailAddress" ref="email" />
            <div v-if="fieldErrors.includes('emailAddress')" class="required-error">E-mail is required</div>
          </div>

          <div class="form-group">
            <label for="signup_password">Password <span class="required">*</span></label>
            <input type="password" class="form-control" id="signup_password" v-model="password" />
            <div v-if="fieldErrors.includes('password')" class="required-error">Password is required and must be at least eight characters in length.</div>
            <div v-if="fieldErrors.includes('password_match')" class="required-error">Password and Password confirmation fields must match</div>
          </div>

          <div class="form-group">
            <label for="signup_confirmPassword">Confirm password <span class="required">*</span></label>
            <input type="password" class="form-control" id="signup_confirmPassword" v-model="confirmPassword" />
          </div>

          <h4>Profile</h4>

          <div class="form-group">
            <label for="signup_name">Name <span class="required">*</span></label>
            <input type="text" class="form-control" id="signup_name" v-model="name" />
            <div v-if="fieldErrors.includes('name')" class="required-error">Name is required</div>
          </div>

          <div class="form-group">
            <label for="signup_organization">Organization name <span class="required">*</span></label>
            <input type="text" class="form-control" id="signup_organization" v-model="organization" />
            <div v-if="fieldErrors.includes('organization')" class="required-error">Organization is required</div>
          </div>

          <div class="form-group">
            <label for="signup_organizationPosition">Organization position</label>
            <input type="text" class="form-control" id="signup_organizationPosition" v-model="organizationPosition" />
          </div>

          <div class="form-group">
            <label for="signup_phoneNumber">Phone number</label>
            <input type="text" class="form-control" id="signup_phoneNumber" v-model="phoneNumber" />
          </div>

          <div class="form-group">
            <label for="signup_mobileNumber">Mobile number</label>
            <input type="text" class="form-control" id="signup_mobileNumber" v-model="mobileNumber" />
          </div>

          <div class="form-group">
            <label for="signup_address">Address</label>
            <input type="text" class="form-control" id="signup_address" v-model="address" />
          </div>

          <div class="form-group">
            <label for="signup_city">City</label>
            <input type="text" class="form-control" id="signup_city" v-model="city" />
          </div>

          <div class="form-group">
            <label for="signup_state">State</label>
            <input type="text" class="form-control" id="signup_state" v-model="state" />
          </div>

          <div class="form-group">
            <label for="signup_countryCode">Country <span class="required">*</span></label>
            <select class="form-control" id="signup_country" v-model="country">
              <option v-for="item in countries" :key="item.code" :value="item.code" :selected="country === item.code">
                {{ item.name }}
              </option>
            </select>
            <div v-if="fieldErrors.includes('country')" class="required-error">Country is required</div>
          </div>
        </div>
      </div>
      <div class="button-row">
        <div class="button strip">
          <button class="btn btn-primary" @click="signup_Click">Sign-up</button>
          <button class="btn btn-secondary" @click="emit('cancel')">Cancel</button>
        </div>
      </div>
    </div>
  </div>

  <TermsOfUseDialog v-if="showTermsOfUse" v-on:accept="acceptTOS" v-on:cancel="showTermsOfUse = false"/>
</template>

<script setup>
import {ref, defineEmits} from "vue";
import countries from "@/lib/utils/Countries";
import {signupAsync} from "@/api/userApi";
import TermsOfUseDialog from "@/components/WelcomePage/TermsOfUseDialog.vue";

const emailAddress = ref('')
const password = ref('')
const confirmPassword = ref('')
const name = ref('')
const organization = ref('')
const organizationPosition = ref('')
const phoneNumber = ref('')
const mobileNumber = ref('')
const address = ref('')
const city = ref('')
const state = ref('')
const country = ref('NZL')

const showTermsOfUse = ref(false)

const acceptedTermsOfUse = ref(false)

const fieldErrors = ref([])

const emit = defineEmits(['signup', 'cancel'])

function createData() {
  return {
    emailAddress: emailAddress.value,
    password: password.value,
    name: name.value,
    countryCode: country.value,
    organization: organization.value,
    organizationPosition: organizationPosition.value,
    phoneNumber: phoneNumber.value,
    mobileNumber: mobileNumber.value,
    address: address.value,
    city: city.value,
    state: state.value,
    acceptTOS: acceptedTermsOfUse.value
  }
}

function passwordMeetsRequirements(p) {
  return typeof p === 'string' && p.length >= 8
}

function validateData(data) {
  fieldErrors.value = []

  let errors = []

  if (!data.emailAddress) errors.push('emailAddress')
  if (!passwordMeetsRequirements(data.password)) errors.push('password')
  if (data.password !== confirmPassword.value) errors.push('password_match')
  if (!data.name) errors.push('name')
  if (!data.organization) errors.push('organization')
  if (!data.countryCode) errors.push('country')

  fieldErrors.value = errors

  return errors.length === 0
}

function doValidationCheck(data) {
  if (!validateData(data)) {
    alert('Some required fields have not been filled in. Please correct the issue highlighted above and try again.')
    return false
  }
  return true
}

async function signup_Click() {
  const data = createData()
  if (!doValidationCheck(data))
    return

  acceptedTermsOfUse.value = false
  showTermsOfUse.value = true
}

async function acceptTOS() {
  acceptedTermsOfUse.value = true

  const data = createData()
  if (!doValidationCheck(data))
    return

  try
  {
    await signupAsync(data)
    alert('Sign-up has completed successfully, but your account is not yet ready for use. You will receive an e-mail within one to two business days. Thank you.')
    acceptedTermsOfUse.value = false
    emit('signup')
  }
  catch(error) {
    if (error.message === 'account_already_exists') {
      alert('Error, an account with the specified e-mail address already exists.')
      return
    }
    alert('There was a problem signing up for an account. Please contact info@climateinsights.global for support.')
  }
}
</script>

<style scoped>

.dialog-content {
  display: flex;
  flex-direction: column;
}

.general-panel {
  width: 90%;
  height: 70%;
  max-width: 800px;
}

.scrollable-host {

}

.scrollable {
  border: 1px solid #ccc;
  padding: 1rem;
}

.sign-up-form {
  flex: 1;
  background-color: white;
}

.sign-up-form h4 {
  font-size: 12pt;
  margin-top: 1rem;
}

.sign-up-form label {
  margin: 1rem 0 0.5rem 0;
}

.required-error {
  color: red !important;
  font-weight: bold;
}
</style>