<script setup>
import {defineEmits} from 'vue'

const emit = defineEmits(['accept', 'cancel'])

</script>

<template>
  <div class="dialog tos">
    <div class="dialog-background"/>
    <div class="dialog-content general-panel tos-panel">

      <h2>Climate Insights: Terms of Use</h2>

      <p>Please read the below Terms of Use. You must agree to these terms to continue creating your account.</p>

      <div class="tos-body">

        <h3>Agreement to Terms</h3>

        <ol>
          <li>
            Climate Insights is a web-based service hosted and operated by ClimSystems Limited (We, Our, Us). We provide:
            <ol style="list-style: lower-alpha">
              <li>Non-registered users (Viewers) with the ability to view some data and other publicly accessible information on Climate Insights/ClimSystems; and</li>
              <li>Registered Users (with a Climate Insights/ClimSystems username and password or under contract for data delivery) with the additional ability to download data (in accordance with applicable licenses) from time to time, as further described on Our website. Registered Users have access to other features and services depending on the plan/contract to which the users or their organisations subscribe.</li>
            </ol>
          </li>
          <li>When We use the terms You and Your, We are referring to Viewers and Registered Users; in short, everyone who uses Our products and services, whether they are individuals or organisations.</li>
          <li>Except as stated in clause 4, by viewing and using Climate Insights/ClimSystems, You are agreeing to be bound by those of these Terms of Use (Terms) that apply to you. If you are a Viewer but not a Registered User, clauses that can only apply to Registered Users do not apply to you. If you are a Registered User, all of the Terms of Use apply to you. If you don't like these Terms or don't want to be bound to them, you are not permitted to use Climate Insights/ClimSystems or data outputs provided.</li>
          <li>
            If you are using Our products or services on behalf of a business, government agency or other legal entity (each a Business), you are accepting these Terms on behalf of that Business and you represent and warrant to us that you are authorised to do so. If that Business has a separate agreement with us that governs its use of Climate Insights/ClimSystems:
            <ol style="list-style: lower-alpha">
              <li>that agreement will take precedence over these Terms in the event of any inconsistency; and</li>
              <li>to the extent that a separate agreement states that these Terms shall not apply to the Business's use of Climate Insights/ClimSystems or to use of Climate Insights/ClimSystems by the Business's personnel when they are using Climate Insights/ClimSystems on behalf of or as personnel of that Business, these Terms shall not apply (despite your clicking to accept these terms when obtaining a Climate Insights/ClimSystems ID or from  a signed contract for deliverables).</li>
            </ol>
          </li>
          <li>We reserve the right to change these Terms and Our privacy policy at any time and will provide You with notice of the change, whether on Climate Insights/ClimSystems or by email. You accept that notice of a change on Climate Insights/ClimSystems will be deemed to be notice to You. Continued use of Our services after any such changes constitutes Your consent to those changes.</li>
          <li>Any new features that enhance or alter Climate Insights/ClimSystems or any individual product or service, including the release of new tools and resources, shall be subject to these Terms.</li>

          <h3>Terms and special meaning</h3>
          <li>
            In these Terms:
            <p>Fees means the charges set out in Our pricing plans or otherwise agreed with us, that You (or your organisation) must pay if You wish to obtain and continue to enjoy a Climate Insights/ClimSystems products or service for which fees are charged (either now or in the future);</p>
            <p>Intellectual Property Rights means all intellectual property rights wherever in the world, whether registered or unregistered, including any application or right of application for such rights (and the “intellectual property rights” referred to above include copyright and related rights, database rights, confidential information, trade secrets, know-how, business names, trade names, trademarks, service marks, passing off rights, unfair competition rights, patents, petty patents, utility models, and rights in designs);</p>
            <p>Climate Insights/ClimSystems means the Climate Insights/ClimSystems website available at https://app.climateinsights.global/# or any successor URL, including all related sites, and subdomains. Climate Insights/ClimSystems APIs (Application Programming Interface) and web services, and any sites on Climate Insights/ClimSystems for which custom domains are used; or Climate Insights/ClimSystems data delivered as raster or other GIS-compatible layers.</p>
            <p>Climate Insights/ClimSystems means the company ClimSystems Limited and its legal successors.</p>
          </li>

          <h3>Access</h3>
          <li>
            To obtain access to a product or service not available to Viewers, You need to:
            <ol style="list-style: lower-alpha">
              <li>be a Registered User or, if You are an organisation, authorise one or more members of staff or third parties to be or become Registered Users for your organisation (in accordance with the plan you have purchased); or be under contract for ClimSystems products or services, and</li>
              <li>obtain Our approval to your account; and</li>
              <li>pay (or have your organisation pay) the applicable Fees (if any) in the manner We prescribe.</li>
            </ol>
          </li>
          <li>Except as expressly permitted by these Terms or agreed with us in writing, You must not sub-license, assign or resell any of Your rights or permissions to use Climate Insights/ClimSystems products or services or allow any unauthorised person to access or use Climate Insights/ClimSystems products or services.</li>
          <li>Except for open source libraries We release or to which We contribute, You have no right to access the object code or source code of Climate Insights/ClimSystems or such code used in generating Our products and services unless We grant you permission in writing to do so.</li>

          <h3>Registration and Climate Insights/ClimSystems IDs (not applicable for the delivery of data products and services through contract)</h3>
          <li>Unless We agree otherwise, You must be at least 13 years old to become a Registered User or otherwise obtain an account, and You must be human. Accounts / Climate Insights/ClimSystems IDs registered by “bots” or other automated means are not allowed and may be terminated.</li>
          <li>If You register for an account, You will be asked to create a Climate Insights/ClimSystems ID (username and password). Climate Insights/ClimSystems IDs are required to access a variety of Climate Insights/ClimSystems products and services. If Your organisation has registered for and purchased a multi-user plan and authorises You to use Climate Insights/ClimSystems through the multi-user plan, it will send You an email invitation to register and obtain a Climate Insights/ClimSystems ID.</li>
          <li>You are responsible for selecting a secret and strong password and for maintaining the security of your Climate Insights/ClimSystems ID.</li>
          <li>You must also provide your full name, a valid email address and any other information We request to complete the registration process and you must keep these details up to date. Fictitious entries are not permitted.</li>
          <li>Each Climate Insights/ClimSystems ID is personal to an individual living person. A single Climate Insights/ClimSystems ID shared by multiple people (including by and within a Business) is not permitted. Each individual living person that wishes to use Climate Insights/ClimSystems must have his or her own Climate Insights/ClimSystems ID.</li>
          <li>We may suspend or disable Your Climate Insights/ClimSystems ID if We consider it necessary for security reasons or if You breach these Terms.</li>
          <li>
            In these Terms:
            <p>Fees means the charges set out in Our pricing plans or otherwise agreed with us, that You (or your organisation) must pay if You wish to obtain and continue to enjoy a product or service for which fees are charged (either now or in the future);</p>
            <p>Intellectual Property Rights means all intellectual property rights wherever in the world, whether registered or unregistered, including any application or right of application for such rights (and the “intellectual property rights” referred to above include copyright and related rights, database rights, confidential information, trade secrets, know-how, business names, trade names, trademarks, service marks, passing off rights, unfair competition rights, patents, petty patents, utility models, and rights in designs);</p>
            <p>Climate Insights/ClimSystems means the Climate Insights/ClimSystems website available at https://app.climateinsights.global/# or any successor URL, including all related sites, subdomains, Climate Insights/ClimSystems APIs and web services, and any sites on Climate Insights/ClimSystems for which custom domains are used; Climate Insights/ClimSystems Limited means the company ClimSystems Ltd Limited and its legal successors and assigns; Climate Insights/ClimSystems Publisher means any Registered User that publishes data on Climate Insights/ClimSystems; and references to agreeing to or permitting something "in writing" includes by electronic means.</p>
          </li>

          <h3>Climate Insights/ClimSystems Data, APIs and Web Services</h3>
          <li>We provide various Climate Insights/ClimSystems data and APIs (Application Programming Interface) and various web services for accessing data and managing Climate Insights/ClimSystems sites, as described on Our website from time to time. To use these APIs and web services, You will need one or more API keys which, when issued, will be linked to Your Climate Insights/ClimSystems ID. If You do not have a Climate Insights/ClimSystems ID, You will need to register to obtain one before You can get an API key.</li>
          <li>
            Any use of the APIs, web services, and Your API key is subject to these Terms plus the following specific terms:
            <ol style="list-style: lower-alpha">
              <li>(a)	abuse of, or excessively frequent requests to Climate Insights/ClimSystems via the APIs and web services may result in the temporary or permanent suspension of Your account's access to the APIs and web services. We will determine abuse or excessive usage by reference to API and web services limits specified for Your plan or, if no relevant limits are specified, in Our sole discretion and We will make reasonable attempts to warn You prior to any suspension. Suspension may occur until your usage returns below the specified limits or, where no limits have been specified for your plan, We are satisfied that there has been a sufficient reduction in the number or nature of requests;</li>
              <li>(b)	We also reserve the right to suspend Your account's access to the APIs and web services in situations where Your plan accommodates You exceeding specified limits (e.g., with additional payments per so many extra requests) but the level of excess is greater than We would have expected; and</li>
              <li>(c)	We reserve the right to modify or discontinue, temporarily or permanently, Your access to the APIs and web services (or any of them) with or without notice. For example (and without limitation), We may need to modify or discontinue access for security reasons or because an API or web service has become deprecated.</li>
            </ol>
          </li>

          <h3>Your Data and Customer Data</h3>
          <li>
            You agree to only upload/supply data to Climate Insights/ClimSystems that You have the right to upload/supply and to comply with any third party licenses applying to data You upload/supply. Without limiting that obligation, every time You upload/supply Your and/or third party data (Customer Data) to Climate Insights/ClimSystems, You warrant and represent to us that You have all requisite rights to upload/supply those Customer Data, and where relevant to make those Customer Data available to others on, Climate Insights/ClimSystems, and that:
            <ol style="list-style: lower-alpha">
              <li>your uploading/supplying of the Customer Data;</li>
              <li>Our use of the Customer Data in accordance with these terms; and</li>
              <li>other Users' use of the Customer Data in accordance with these terms and any applicable licensing terms or terms of access you apply to or associate with the Customer Data, will not:</li>
              <li>breach any laws or legally binding codes;</li>
              <li>infringe any person's Intellectual Property Rights or other legal rights; or</li>
              <li>give rise to any cause of action against us or You or any third party, in each case in any jurisdiction and under any applicable law.</li>
            </ol>
          </li>
          <li>If You upload/supply third party data to Climate Insights/ClimSystems or copy another Registered User's data into Your Climate Insights/ClimSystems account (see clauses 27-28) that will be accessible to Viewers and/or other Registered Users, You will ensure the third party's licensing terms or terms of access, under which use or access by others is permitted, are displayed with the data or each data layer (as applicable).</li>
          <li>If We receive a complaint or claim from a licensor or supplier of any third party data, or if We otherwise have reason to believe You have breached clause 20 or 21, We may give written notice to You to cease using that licensor's or supplier's data and You agree to comply with such notice, which may include deletion of the relevant data.</li>
          <li>
            If We reasonably suspect that You have breached any of clauses 20 to 22 We may:
            <ol style="list-style: lower-alpha">
              <li>remove (either completely or from public access), suspend, amend, or delete the relevant Customer Data; and/or</li>
              <li>suspend Your access to Climate Insights/ClimSystems and/or any or all of Our products and services while We investigate the matter.</li>
            </ol>
          </li>
          <li>
            We will not openly publish any Customer Data that You designate (through self-service functionality within Climate Insights/ClimSystems) is to be subject to restricted access. To avoid doubt, You accept that if:
            <ol style="list-style: lower-alpha">
              <li>You grant access to such Customer Data to another Registered User;</li>
              <li>that Registered User makes a copy of it into their own Climate Insights/ClimSystems account; and</li>
              <li>the Registered User changes the default 'private' setting that applies to uploaded and copied data so as to make the Customer Data publicly accessible, the Registered User (and not Climate Insights/ClimSystems) is responsible for the open publishing.</li>
            </ol>
          </li>

          <h3>Licence to Use</h3>
          <li>We do not claim ownership of Customer Data but You grant us a non-exclusive, perpetual and irrevocable licence, or shall otherwise ensure that We have equivalent rights, to store, copy, parse, reformat, publish and otherwise use the Customer Data and any other content You upload or post to Climate Insights/ClimSystems for the purposes of operating Climate Insights/ClimSystems, providing Our products and services, fulfilling Our other obligations under these Terms and exercising Our rights under these Terms.</li>
          <li>
            The licence above includes (without limitation) the right to do things like:
            <ol style="list-style: lower-alpha">
              <li>copy the Customer Data to Our database and make backups of it;</li>
              <li>parse it into search indices; and</li>
              <li>display it to and share it with users and enable Registered Users to make copies into their own Climate Insights/ClimSystems accounts, except, in the case of (b) and (c), to the extent you have restricted access to it.</li>
            </ol>
          </li>

          <h3>Licence to Registered Users</h3>
          <li>
            To the extent that You have granted access to other users to the Customer Data You upload or otherwise have on Climate Insights/ClimSystems (for example, by making the Customer Data public or allowing particular Registered Users to access the Customer Data), the Accessible Customer Data:
            <ol style="list-style: lower-alpha">
              <li>may be viewed by others on Climate Insights/ClimSystems who have access to it; and</li>
              <li>can be copied by other Registered Users who have access to it to their own Climate Insights/ClimSystems accounts.</li>
            </ol>
          </li>
          <li>
            In the case of Accessible Customer Data that You own:
            <ol style="list-style: lower-alpha">
              <li>You grant each Registered User who has access to it a non-exclusive, worldwide licence to copy this Accessible Customer Data or parts of it (at the Registered User's election) into the Registered User's own Climate Insights/ClimSystems account and combine it with other data in their account.</li>
              <li>The licence in (a) applies to Registered Users who have direct access to Your published version of the data and to Registered Users who have access through another Registered User who has made a copy of it under the authority of this clause 28 or any other licence You associate with the data.</li>
              <li>If, when a Registered User makes a copy into their own Climate Insights/ClimSystems account, You had openly published the data, then the Registered User may likewise openly publish the Registered User's copy as long as the Registered User complies with Your licensing terms (if any).</li>
              <li>If, when a Registered User makes a copy into their own Climate Insights/ClimSystems account, You had not openly published the data, then the Registered User is not permitted to openly publish the data unless the licence you associate with the data allows the Registered User to do so or You otherwise allow the Registered User to do so.</li>
              <li>Whether Registered Users have any additional rights in relation to Your Accessible Customer Data depends on the licence(s) (if any) that you have applied to the Accessible Customer Data.</li>
            </ol>
          </li>
          <li>In the case of Accessible Customer Data that You do not own and that is owned by a third party, You are responsible for ensuring that the data is licensed or otherwise made available under terms that grant the permissions referred to in clause 28 to other Registered Users who have access to it (whether directly or indirectly as described in clause 28(b)). Whether Registered Users have any additional rights in relation to this Accessible Customer Data depends on the licence(s) or access rights that the third party has applied to the Accessible Customer Data.</li>
          <li>The uses in clauses 28 and 29 do not limit any rights or freedoms conferred on other Registered Users by the licenses that you associate, or a third party has associated with the Accessible Customer Data.</li>
          <li>
            Once another Registered User has made a copy of Your Accessible Customer Data into their own Climate Insights/ClimSystems account as permitted by clauses 27-29, You will not seek to prohibit the Registered User from retaining and using their copy in accordance with those clauses, even if You decide to remove your version of the data from Your Climate Insights/ClimSystems account or restrict access to it. This clause 31:
            <ol style="list-style: lower-alpha">
              <li>is subject to any separate terms We have agreed with You that expressly override this clause; and</li>
              <li>does not limit Our rights under clause 22. For example, if You remove Accessible Customer Data because You discover it infringes a third party's rights and We receive a complaint relating to other Registered Users' publication of that data, We may elect to require those Registered Users to remove it or We may elect to delete it.</li>
            </ol>
          </li>

          <h3>Our Intellectual Property Rights</h3>
          <li>Except for Customer Data, open source components and other third-party components and services, and unless otherwise indicated, We or Our licensors are the exclusive owners of the Intellectual Property Rights in Climate Insights/ClimSystems and in content on Climate Insights/ClimSystems.</li>
          <li>You may not use Our Intellectual Property Rights and content unless authorised to do so under these Terms or otherwise by us in writing.</li>

          <h3>Use of Third-Party Data</h3>
          <li>You acknowledge that the use of third-party data (whether uploaded by You or made available by Us or anyone else) may be subject to separate licenses or agreements. It is not Our role to enforce compliance with such licenses or agreements, but You agree with Us that You will comply with those licenses or agreements when accessing and using the third-party data.</li>

          <h3>Fees and Account Upgrades and Downgrades</h3>
          <li>You (or your organisation) must pay all applicable Fees (if any) by their due dates and in the manner We specify. Fees are subject to change on 30 days' notice, with revised Fees applying from the date of the currently paid-up period. Such notice may be provided at any time by Our posting the changes to https://app.climateinsights.global/# or through negotiation and provision of an invoice.</li>
          <li>All Fees are exclusive of all taxes, levies or duties imposed by taxing authorities or law, and You shall be responsible for payment of all such taxes, levies or duties that may be payable in Your jurisdiction. For example, customers in New Zealand will be charged goods and services tax in addition to the Fees.</li>
          <li>If You upgrade from a free account to a paid account, We will bill You immediately unless otherwise agreed. If You upgrade from one paid account to another paid account and have been paying by way of a credit card, You agree that We may charge Your credit card for any increased amount at the next billing cycle.</li>
          <li>Our Fees are charged in advance on a monthly or annual basis or as otherwise agreed. There will be no refunds or credits for partial periods of service, no upgrade/downgrade refunds and no refunds for periods during which You elect not to use Your account.</li>
          <li>If You are able to and do change from a monthly to an annual or other agreed billing cycle, We will bill You for the full annual or other agreed period at the next monthly billing date.</li>
          <li>You acknowledge that downgrading Your account may cause a loss of data, features or the capacity of Your account. We do not accept any liability for such loss. We suggest that, before downgrading, You review the features and any capacity limits of the account type to which You propose to downgrade and, where relevant, delete and/or backup data as may be required.</li>
          <li>If You don't pay Fees owing to us when due, We may require You and You agree  to pay on demand, default interest on any amount You owe us at 10% per annum calculated on a daily basis, from the date when payment was due until the date when payment is actually made by You. We also reserve the right to suspend or terminate Your account if You fail to pay or are late in the payment of applicable Fees and/or interest. You acknowledge and accept that this may result in a loss of data.</li>

          <h3>Support Plans</h3>
          <li>If You (or your organisation) have purchased an optional support plan, We will provide support in accordance with that plan.</li>

          <h3>Indemnity</h3>
          <li>You will indemnify Us and keep Us indemnified against all liabilities, damages, losses, costs and expenses (including legal expenses) suffered or incurred by Us and arising as a result of any breach by You of any of these terms. This clause does not apply to any government department or government agency to the extent that, under specific legislation applying to it, it is either not permitted to grant an indemnity or can only grant an indemnity if expressly authorised by its chief executive or a Minister, Secretary or equivalent person in accordance with procedures specified in the specific legislation.</li>

          <h3>Cancellation and Termination</h3>
          <li>If You are a Registered User, You are responsible for properly cancelling Your account. You can cancel Your account at any time by contacting Climate Insights/ClimSystems Support at support@climate.insights.global or by using any other cancellation process (if any) that may be published on Our website from time to time. Your account will not be cancelled until You receive an acknowledgement of receipt from Us (We seek to process cancellation requests as soon as possible).</li>
          <li>All Your Customer Data and other content will be deleted from Climate Insights/ClimSystems upon cancellation or within a reasonable period afterwards. This information cannot be recovered once Your account is cancelled. It is, therefore, Your responsibility to export/copy any Customer Data or other content within Your account and site(s) that You wish to retain before cancelling Your account.</li>
          <li>If You are a paying customer and cancel Your account before the end of Your current paid up month or year (as applicable), Your cancellation will take effect at the end of the relevant month or year and You will not be charged again.</li>
          <li>We may suspend Your access to Climate Insights/ClimSystems and/or the provision of any of Our services, or terminate Your account, in each case with or without notice, if You breach any of these terms.</li>
          <li>Climate Insights/ClimSystems site holders may suspend or terminate your access to their sites or to particular datasets on their sites, in each case with or without notice, if You breach any term of this agreement with Us that is relevant to the operation of their sites and the use of data on those sites, including without limitation clause 34 relating to the use of third party data. This clause 48 is intended to be for the benefit of Climate Insights/ClimSystems Site holders and to be enforceable by them under the Contract and Commercial Law Act 2017.</li>
          <li>We also reserve the right to suspend or terminate Our provision of the Climate Insights/ClimSystems products and services or any part of them, as well as the right to refuse service to anyone, for any reason at any time.</li>

          <h3>Agreement to Privacy Policy</h3>
          <li>Our treatment of Your personal information is described in Our privacy policy at the Climate Insights/ClimSystems website. In agreeing to these Terms or using Climate Insights/ClimSystems, You will be taken to have read and agreed to the terms of that privacy policy.</li>

          <h3>Disclaimers and Exclusion of Liability</h3>
          <li>You acknowledge that Climate Insights/ClimSystems products and services (including all associated and third party services), all Climate Insights/ClimSystems Publishers' data, all Climate Insights/ClimSystems sites and their owners' data and all other content displayed on or otherwise available from the Climate Insights/ClimSystems websites are provided on an “as is” and “as available” basis. To the maximum extent permitted by law, all warranties and representations in relation to such products, services, sites, data and content are excluded. Without limitation to the breadth of this clause, You acknowledge that We do not and that Climate Insights/ClimSystems Publishers and Climate Insights/ClimSystems site owners do not warrant or represent that any such products, services, sites, data or content will operate without interruption or will be error-free. If You are a business, You also acknowledge that You are acquiring services in connection with Climate Insights/ClimSystems for the purposes of a business and that the Consumer Guarantees Act 1993 does not apply to these Terms or Your purchase of products or services under them.</li>
          <li>
            Climate Insights/ClimSystems disclaim and exclude, and You expressly understand and agree that We and Climate Insights/ClimSystems owners will not be liable for, any direct, indirect, incidental, special, consequential, exemplary or other damages, losses, expenses or costs of any kind and on any legal basis (including negligence), including but not limited to any and all damages for loss of profits, goodwill, use, data or other intangible losses (even if We have been advised of the possibility of such damages), resulting from:
            <ol style="list-style: lower-alpha">
              <li>Your use of or inability to use Climate Insights/ClimSystems or related services, any Climate Insights/ClimSystems Site, Customer Data or other data or content on or linked to from Climate Insights/ClimSystems;</li>
              <li>Your use of Our APIs and web services or third-party products or services that access data via the APIs and web services;</li>
              <li>unauthorised access to or alteration of Your transmissions, Customer Data or other data or content;</li>
              <li>statements or conduct of any third party on Climate Insights/ClimSystems; or</li>
              <li>any other matter relating to Climate Insights/ClimSystems or Our services.</li>
            </ol>
          </li>
          <li>Clauses 51 and 52 are intended to be for the benefit of Us under the Contract and Commercial Law Act 2017.</li>

          <h3>Climate Insights/ClimSystems Compliance With Privacy and Anti-spam Laws</h3>
          <li>If you are the holder of a Climate Insights/ClimSystems site You agree that you will, in relation to Your use of Climate Insights/ClimSystems and Your Climate Insights/ClimSystems site, comply with all privacy and anti- spam laws that apply to You.</li>

          <h3>General Terms</h3>
          <li>You warrant and represent to Us that You have the legal right and authority to enter into these Terms and perform Your obligations under them.</li>
          <li>To avoid doubt, the latest version of these Terms applies to all Customer Data You add or have added to Climate Insights/ClimSystems, regardless of when You added the Customer Data and whether You added Customer Data prior to implementation of the latest version. The latest version is deemed to apply as if it had been in place when You first registered with Climate Insights/ClimSystems.</li>
          <li>
            You must not:
            <ol style="list-style: lower-alpha">
              <li>modify, adapt or hack Climate Insights/ClimSystems;</li>
              <li>make or modify Your own or any other website or application to appear as if it is affiliated with, or endorsed or sponsored by, Climate Insights/ClimSystems or Us or otherwise falsely imply an association with Climate Insights/ClimSystems or Us;</li>
              <li>frame, embed or otherwise re-publish or re-distribute Climate Insights/ClimSystems data (or any part of it) in another website or application, or by any other means, unless authorised by Us (for example, by Our provision to You of embeddable code for such purpose); or</li>
              <li>use Our branding assets (such as but not limited to Our logo) except as expressly authorised by us, either in writing to You or through, for example, a published trademark or similar usage policy.</li>
            </ol>
          </li>
          <li>
            You promise:
            <ol style="list-style: lower-alpha">
              <li>not to upload or transmit to or from Climate Insights/ClimSystems any material (including datasets, comments, and feedback) that is obscene, defamatory, threatening, invasive of privacy or that is otherwise, in Our opinion, injurious or objectionable, including any worms or viruses or any code of a destructive nature; and</li>
              <li>to comply with any usage or other guidelines posted to Climate Insights/ClimSystems from time to time.</li>
            </ol>
          </li>
          <li>You agree that We may implement limits, through the usage guidelines mentioned above and/or technical means, on how much data a Registered User is able to download per specified time period (e.g., hour, day, month). We may elect to implement such limits for free and/or paid services.</li>
          <li>Abuse of any kind of Our customers, employees, members, or officers may, at Our discretion, result in immediate account termination, without refund of any Fees already paid.</li>
          <li>Our failure to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision.</li>
          <li>Unless otherwise expressly agreed in writing, these Terms constitute the entire agreement between You and Us and govern Your use of Climate Insights/ClimSystems and Our products and services, superseding any prior agreements between You and Climate Insights/ClimSystems (including, but not limited to, any prior versions of these Terms).</li>
          <li>
            If any provision of these Terms or its application to any person or circumstances is held to be illegal or unenforceable:
            <ol style="list-style: lower-alpha">
              <li>such provision will continue to apply, to the maximum extent permitted by law, to other persons or circumstances not affected by the illegality or unenforceability;</li>
              <li>the illegal or unenforceable provision will be deemed to be amended to reflect the original intention as nearly as possible in accordance with applicable law in respect of those persons or circumstances giving rise to the illegality or unenforceability; and</li>
              <li>the remainder of these terms of use shall continue in full force and effect.</li>
            </ol>
          </li>
          <li>
            These Terms are governed by New Zealand law. Without limitation to Our ability to secure interim relief in any jurisdiction, You agree to submit to the exclusive jurisdiction of New Zealand courts in relation to any dispute concerning these Terms, their formation or Your use of Climate Insights/ClimSystems.
          </li>
          <h3>Enquiries or Complaints</h3>
          <li>
            <p>If You have any enquiries or complaints, please address them to: Climate Insights/ClimSystems Customer Care</p>

            <p>
              PO Box 638<br/>
              Waikato Mail Centre<br/>
              Hamilton 3204<br/>
              NEW ZEALAND<br/>
              support@climateinsights.com
            </p>
          </li>
        </ol>
      </div>

      <div class="button-row">
        <div class="button strip">
          <button class="btn btn-primary" @click="emit('accept')">Agree</button>
          <button class="btn btn-secondary" @click="emit('cancel')">Decline</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.general-panel {
  width: 90%;
  height: 80%;
  overflow-y: scroll;
}
</style>