<template>
  <div class="dialog create-project">
    <div class="dialog-background" @click="emit('cancel')"/>
    <div class="dialog-content general-panel">
      <div class="window-controls"><a href="#" @click.prevent="emit('cancel')">&times;</a></div>
      <h3>Create project</h3>
      <label>
        <span>Project name:</span>
        <input type="text" v-model="projectName"/>
      </label>
      <label class="description-label">
        <span>Description (optional):</span>
        <textarea v-model="description"/>
      </label>
      <div class="button-row">
        <div class="button-strip">
          <button class="btn btn-primary" @click="createProject_Click">Create</button>
          <button class="btn btn-secondary" @click="emit('cancel')">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, ref } from "vue";
import { createProjectAsync} from "@/api/projectsApi";
import {useUserStore} from "@/stores/userStore";

const emit = defineEmits(['cancel', 'project-created'])
const projectName = ref('')
const description = ref('')
const userStore = useUserStore()

async function createProject_Click() {
  if (projectName.value === '') {
    alert('Please enter a project name.')
    return
  }

  try
  {
    await createProjectAsync(userStore.token, {name: projectName.value, description: description.value})
    emit('project-created')
  }
  catch(error) {
    alert('There was an error creating your project.')
  }
}

</script>

<style scoped>
.dialog-content {
  display: flex;
  flex-direction: column;
  min-width: 500px;
  min-height: 300px;
}

input, textarea {
  width: 100%;
}

.description-label {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.description-label textarea {
  flex: 1;
}

.button-strip {
  display: flex;
  flex-direction: row;
  justify-content: right;
}
</style>