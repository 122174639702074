<template>

  <div class="welcome-dash">
    <div class="welcome-dash-content">
      <div class="content">
        <div class="content-title">
          <div class="title-name">
            Welcome
            <span class="name">{{ userStore.name }}</span>
          </div>

          <div class="title-logo">
            <img v-if="userStore.hasBrandingThemeImage" :src="userStore.brandingTheme.objectUrl" class="branding-image" :alt="userStore.brandingTheme.profile.companyName" />
          </div>
        </div>
        <div class="content-body">
          <div class="projects-body content-body-item">
            <project-list-view class="flex-fill">
              <template #filter-bar-content>
                <h4>Projects</h4>
              </template>
            </project-list-view>
          </div>
          <div class="updates-body content-body-item" style="flex: 1.4">
            <subscription-info class="subscription-info" :sub="userStore.subscription" :usage-stats="userStore.subscriptionUsageStats" @refresh="subInfo_Refresh" :is-loading="isLoading"/>
            <update-history class="flex-fill"/>
          </div>
        </div>
      </div>
    </div>
    <loading-indicator v-if="isLoading"/>
  </div>
</template>

<script setup>
import {ref} from "vue";
import ProjectListView from "@/components/ProjectsPage/ProjectListView.vue";
import UpdateHistory from "@/components/WelcomePage/UpdateHistory.vue";
import {useUserStore} from "@/stores/userStore";
import SubscriptionInfo from "@/components/WelcomePage/SubscriptionInfo.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";

const userStore = useUserStore()
const isLoading = ref(false)

async function subInfo_Refresh() {
  isLoading.value = true
  try
  {
    await userStore.updateSubscriptionInfo()
  }
  finally
  {
    isLoading.value = false
  }
}
</script>

<style lang="scss" scoped>
  .welcome-dash {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .welcome-dash-content {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: row;
    max-width: 1400px;
    overflow: hidden;
  }

  .content {
    padding: 2rem;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .content-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }

  .content-body {
    flex: 1;
    margin-top: 0.5rem;
  }

  .title-logo img {
    height: 50px;
  }

  .title-name {
    font-size: 12pt;
  }

  .title-name .name {
    font-size: 22pt;
    display: block;
    line-height: 12pt;
  }

  .content-body {
    display: flex;
    flex-direction: row;
  }

  .content-body-item {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .updates-body {
    padding-left: 1rem;
  }

  .subscription-info {
    background-color: #fff;
    padding: 2rem;
    max-width: 400px;
    border-radius: 2rem;
    border: 1px solid #eee;
    margin-bottom: 1rem;
  }

</style>